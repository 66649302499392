import React from "react";

const DialogflowMessenger = () => {

  return (
    <div>
      <df-messenger
        oauth-client-id="1057247884201-q9rr5h3igdr17hgn3unsubrl54ev9c7m.apps.googleusercontent.com"
        location="us-central1"
        project-id="ghost-first"
        agent-id="bf4d664b-0342-4080-ac85-d237058b38b4"
        language-code="en"
        max-query-length="-1"
      >
        <df-messenger-chat-bubble chat-title="Assistente AI"></df-messenger-chat-bubble>
      </df-messenger>

      {/* Stile per posizionare il widget di Dialogflow Messenger */}
      
    </div>
  );
};

export default DialogflowMessenger;
