import React, { Component } from "react";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutData: [],
    };
    
  }


  componentDidMount() {
    this.fetchAboutData();
  }

  fetchAboutData = async () => {

    const { data: aboutData, error: aboutError } = await supabase
      .from("home")
      .select("*");


    if (aboutError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        aboutError
      );
      return;
    }

    if (aboutData) {
      this.setState({ aboutData: aboutData }); // Aggiornato qui
    }
  

  };

  

  render() {
  const aboutDataWithID2 = this.state.aboutData.find((item) => item.id === 2);
   const aboutDataWithID3 = this.state.aboutData.find((item) => item.id === 3);
    const aboutDataWithID4 = this.state.aboutData.find((item) => item.id === 4);
    return (

        <div className="about-area about-position-top pb--120 bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5 col-md-12">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-50px",
                    }}
                  >
                    <img
                      src="/assets/images/homeweb.webp"
                      alt="Immagine di uno space shuttle in partenza"
                      loading="lazy"
                      style={{ borderRadius: "7px" }}
                    />
                  </div>
                </div>

                <div className="col-lg-7 col-md-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">
                        {aboutDataWithID2 ? aboutDataWithID2.titolo : ""}
                      </h2>
                      <p className="description">
                        {aboutDataWithID2 ? aboutDataWithID2.descrizione : ""}
                      </p>
                    </div>
                    <div className="row mt--30 mt_sm--10">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">
                            {aboutDataWithID3 ? aboutDataWithID3.titolo : ""}
                          </h3>
                          <p>
                            {aboutDataWithID3
                              ? aboutDataWithID3.descrizione
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">
                            {aboutDataWithID4 ? aboutDataWithID4.titolo : ""}
                          </h3>
                          <p>
                            {" "}
                            {aboutDataWithID4
                              ? aboutDataWithID4.descrizione
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    );
  }
}
export default About;